* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.site-layout-content {
  min-height: calc(100vh - 185px);
  padding: 1rem;
  margin: 1rem;
}
